'use client';

import { language } from 'i18n';
import { useTranslation } from 'i18n/client';
import { usePathname } from 'next/navigation';

interface HeaderPageTitleProps {
  lng: language;
}

export function HeaderPageTitle({ lng }: HeaderPageTitleProps) {
  const { t } = useTranslation(lng, 'header');

  const pathname = usePathname();

  const cleanPathName = pathname?.replace(/\/(pt|es)\//, '') ?? '';

  const PAGE_NAMES = {
    '': 'home',
  };

  const pageName = PAGE_NAMES[cleanPathName as keyof typeof PAGE_NAMES];

  return (
    <p className="absolute right-[50%] flex translate-x-[50%] items-center justify-center px-5 py-2 text-center text-[16px] font-bold leading-[21px] text-[#0F1419] lg:hidden">
      {t(pageName)}
    </p>
  );
}
