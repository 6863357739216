import React, { useEffect } from 'react';

interface useOutsideClickProps {
  ref: React.RefObject<HTMLDivElement>;
  callback: () => void;
}

export function useOutsideClick({ ref, callback }: useOutsideClickProps) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const clickedOutside =
        ref.current && !ref.current.contains(event.target as Node);

      if (clickedOutside) callback();
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
