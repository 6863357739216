'use client';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { language } from 'i18n';
import { useTranslation } from 'i18n/client';
import { usePathname, useRouter } from 'next/navigation';
import { Flag } from '../flag';

interface LanguageSelectorProps {
  lng: language;
}

export function LanguageSelector({ lng }: LanguageSelectorProps) {
  const { t } = useTranslation(lng, 'global');

  const pathname = usePathname();

  const router = useRouter();

  const LANGUAGES = [
    {
      id: 'pt',
      label: 'Português',
    },
    {
      id: 'es',
      label: 'Español',
    },
  ];

  const handleLanguageClick = (lng: string) => {
    if (lng === 'es') {
      window.location.href = 'https://skeelo.com/libros-audiolibros/latam/';

      return;
    }

    const newUrl = pathname?.replace(/\/(pt|es)\//, `/${lng}/`) ?? `/${lng}/`;

    router.push(newUrl);
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          title={t('open_language_selector')}
          aria-label={t('open_language_selector')}
          className="overflow-hidden rounded-[4px] transition-opacity hover:opacity-70"
        >
          <Flag lng={lng as language} />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Group className="flex min-w-[200px] flex-col items-stretch gap-1 rounded-xl bg-paper-white p-2 [box-shadow:0px_0px_2px_0px_#919E9B29,_0px_4px_12px_0px_#4C575414]">
          {LANGUAGES.map(language => (
            <DropdownMenu.Item
              key={language.id}
              className={clsx(
                'flex cursor-pointer select-none items-center rounded-lg p-2 outline-none transition-colors',
                lng === language.id
                  ? 'bg-brand-100-positive'
                  : 'bg-paper-white hover:bg-brand-100-positive'
              )}
              onClick={() => handleLanguageClick(language.id)}
            >
              <Flag lng={language.id as language} />{' '}
              <span className="ml-2 text-body-nano text-text-900">
                {language.label}
              </span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Group>

        <DropdownMenu.Arrow className="fill-text-white-positive" />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
