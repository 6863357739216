import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import styles from './styles.module.scss';

interface HeaderLinkProps extends LinkProps {
  children: React.ReactNode;
  isActive: boolean;
}

export function HeaderLink({
  children,
  isActive,
  href,
  ...rest
}: HeaderLinkProps) {
  const hrefProp = href as string;

  const props = {
    className: clsx(
      styles.link,
      'mb-5 ml-5 rounded-t-[20px] text-[14px] leading-[19px] text-[#0F1419] lg:mb-0 lg:ml-0 lg:px-5 lg:pb-[9px] lg:pt-[5px] lg:text-[16px] lg:leading-[21px]',
      isActive
        ? `font-bold lg:bg-paper-white ${styles.active}`
        : 'bg-transparent hover:bg-paper-white'
    ),
    ...rest,
  };

  if (hrefProp.startsWith('http'))
    return (
      <a href={hrefProp} target="_blank" rel="noreferrer noopener" {...props}>
        {children}
      </a>
    );

  return (
    <Link href={hrefProp} {...props}>
      {children}
    </Link>
  );
}
