'use client';

import clsx from 'clsx';

import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { useCheckBenefit } from '@hooks/useCheckBenefit';

import { GA_ID } from '@constants/Analytics';

import styles from './styles.module.scss';

type Message = {
  type: string;
  value: string;
};

const IFRAME_URL = 'https://check-benefit.skeelo.com/';

function CheckBenefitFrame(): JSX.Element | null {
  const { height, handleUpdateHeight, iframeRef } = useCheckBenefit();

  const params = useParams();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  let language = 'pt';

  const checkBenefitSearchParams = searchParams?.get('checkBenefit');

  const shouldAutoOpenIFrame = checkBenefitSearchParams === 'true';

  const paramsLng = params?.lng;
  const isLatamPage = pathname?.includes('latam');

  if (paramsLng) {
    language = paramsLng as string;
  } else if (isLatamPage) {
    language = 'es';
  }
  const shouldRenderFrame = !pathname?.includes('colaboradores');

  const handleMessage = (event: MessageEvent) => {
    const message: Message = event.data;

    if (message?.type === 'update-height') {
      handleUpdateHeight(Number(message.value));

      return;
    }

    if (message?.type === 'update-data') {
      const currentData = sessionStorage.getItem('benefit-data');

      const parsedData = JSON.parse(currentData || '{}');

      const newData = {
        ...parsedData,
        ...JSON.parse(message.value),
      };

      sessionStorage.setItem('benefit-data', JSON.stringify(newData));

      return;
    }

    if (message?.type === 'open-link') {
      window.open(message.value, '_blank');
    }
  };

  useEffect(() => {
    if (!shouldRenderFrame) return;

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [shouldRenderFrame]);

  useEffect(() => {
    if (!shouldRenderFrame) return;

    const storedData = sessionStorage.getItem('benefit-data');

    const contentWindow = iframeRef.current?.contentWindow;

    if (contentWindow) {
      if (storedData) {
        const setDefaultDataMessage: Message = {
          type: 'set-default-data',
          value: storedData || '{}',
        };

        contentWindow.postMessage(setDefaultDataMessage, {
          targetOrigin: '*',
        });
      }
    }
  }, [shouldRenderFrame, iframeRef.current]);

  if (!shouldRenderFrame) {
    return null;
  }

  return (
    <div
      className={clsx(styles.wrapper, height > 52 && styles.withShadow)}
      style={{ height }}
    >
      <iframe
        sandbox="allow-scripts allow-forms allow-same-origin"
        title="Verificar benefício"
        src={`${IFRAME_URL}?frameorigin=site&applink=https://skeelo.onelink.me/zG2c/nk5uf8e8${
          GA_ID ? `&gaId=${GA_ID}` : ''
        }&lang=${language}${shouldAutoOpenIFrame ? `&autoOpen=true` : ''}`}
        ref={iframeRef}
        height={height}
        style={{ height }}
      />
    </div>
  );
}

export default CheckBenefitFrame;
