import clsx from 'clsx';
import { AnchorHTMLAttributes } from 'react';

interface HeaderExternalLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  isActive?: boolean;
}

export function HeaderExternalLink({
  children,
  isActive = false,
  ...rest
}: HeaderExternalLinkProps) {
  return (
    <a
      className={clsx(
        'rounded-[20px] px-5 py-2 text-[16px] leading-[21px] text-[#0F1419] transition-colors',
        isActive
          ? 'bg-brand-100-positive font-bold'
          : 'bg-text-100 lg:bg-paper-white lg:hover:bg-brand-100-positive'
      )}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  );
}
