'use client';

import { ZENDESK_KEY } from 'constants/Analytics';

import Script from 'next/script';

function Zendesk(): JSX.Element | null {
  if (!ZENDESK_KEY) {
    return null;
  }

  return (
    <Script
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`}
    />
  );
}

export default Zendesk;
