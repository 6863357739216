import { language } from 'i18n';
import Image from 'next/image';

interface FlagProps {
  lng: language;
}

export function Flag({ lng }: FlagProps) {
  return <Image src={`/img/flags/${lng}.svg`} width={24} height={18} alt="" />;
}
